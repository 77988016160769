import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '@shared/services/notificaiton.service';
import { ContactUsService } from '@shared/services/contact-us.service';
import { CAPTCHA_SITE_KEY } from '@assets/config/captchakey.config';

@Component({
  selector: 'app-contact-us-layout',
  templateUrl: './contact-us-layout.component.html',
  styleUrls: ['./contact-us-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsLayoutComponent implements OnInit {
  contactForm: FormGroup;
  isLoading: boolean = false;
  captcha: string | null;
  siteKey: string = CAPTCHA_SITE_KEY;
  constructor(
    private _notification: NotificationService,
    private _cdr: ChangeDetectorRef,
    private _ContactUsService: ContactUsService
  ) {}

  ngOnInit() {
    this.contactForm = this._ContactUsService.initContactForm();
    this.contactForm.get('email')?.valueChanges.subscribe((e) => {
      this.contactForm.get('email')?.setValue(e.toLowerCase(), { emitEvent: false });
    });
  }

  onSubmit() {
    this.isLoading = true;
    this._ContactUsService.onSubmit(this.contactForm).subscribe((res) => {
      if (res.code === 200) {
        this.successNoti();
        grecaptcha.reset();
        this.contactForm.reset();
        this.isLoading = false;
        this._cdr.detectChanges();
      } else {
        this.errorNoti();
      }
      this.isLoading = false;
    });
  }

  resolved(captchaResponse: string | null) {
    this.captcha = captchaResponse;
    this.contactForm.get('captcha')?.setValue(captchaResponse);
  }

  successNoti(): void {
    this._notification.success(
      'Your email has been sent successfully!',
      'Thank you for contacting Wowtrip!.'
    );
  }

  errorNoti(): void {
    this._notification.error(
      'Oops! It seems there was an error sending the email',
      'Please try again later or contact support for assistance.'
    );
  }
}
