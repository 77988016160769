import { Injectable } from '@angular/core';
import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  defaultOptions: NzNotificationDataOptions<{}> = {
    nzDuration: 4000,
    nzPauseOnHover: true,
    nzPlacement: 'bottomLeft'
  };
  constructor(private _notification: NzNotificationService) {}

  success(
    title: string,
    content: string,
    options: NzNotificationDataOptions<{}> = this.defaultOptions
  ) {
    this._notification.success(title, content, options);
  }

  error(
    title: string,
    content: string,
    options: NzNotificationDataOptions<{}> = this.defaultOptions
  ) {
    this._notification.error(title, content, options);
  }
}
