import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  throwError
} from 'rxjs';
import { LoadingService } from '@shared/services/loading.service';
import { AuthService } from '@admin/auth/services/auth.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private _AuthService = inject(AuthService);
  private _LoadingService = inject(LoadingService);
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<{
    token: string;
    csrfToken: string;
    tokenExp: number;
  } | null> = new BehaviorSubject<{ token: string; csrfToken: string; tokenExp: number } | null>(
    null
  );

  constructor() {}

  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 401:
        break;
      case 403:
        break;
      default:
        break;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this._LoadingService.setLoading(true);
    }
    this.activeRequests++;

    const url = req.url;
    let newReq = req;

    if (this.shouldAddHeaders(url)) {
      const token = this._AuthService.accessToken;
      const csrfToken = this._AuthService.csrfToken;
      if (token && csrfToken) {
        newReq = this.addTokenHeader(req, token, csrfToken);
      }
    }
    console.log({ newReq });
    // ========================
    if (newReq.url.includes('user/refresh')) {
      return next.handle(newReq).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this._LoadingService.setLoading(false);
          }
        })
      );
    }
    // ========================

    return next.handle(newReq).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        if (!req.url.includes('user/login') && error.status === 401) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this._LoadingService.setLoading(false);
        }
      })
    );
  }

  private addTokenHeader(req: HttpRequest<any>, token: string, csrfToken: string) {
    const headers = {
      'X-TOKEN': `${token}`,
      'X-CSRF-TOKEN': `${csrfToken}`
    };
    return req.clone({ setHeaders: headers });
  }

  private shouldAddHeaders(url: string): boolean {
    return url.includes('v2') && !url.includes('user/logout') && !url.includes('user/refresh');
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._AuthService.refreshNewToken().pipe(
        switchMap((res) => {
          console.log({ 'user/refresh-token': res });
          this.isRefreshing = false;
          this._AuthService.updateToken(res.token, res.csrfToken, res.tokenExp);
          this.refreshTokenSubject.next(res);
          return next.handle(this.addTokenHeader(req, res.token, res.csrfToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this._AuthService.logout();
          return throwError(err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(req, token!.token, token!.csrfToken)))
    );
  }
}
