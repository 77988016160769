import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DefaultDialogComponent } from '@shared/dialogs/default-dialog/default-dialog.component';
import { CdkScrollable } from '@angular/cdk/overlay';
import { FormValidateDirective } from '@shared/directives/form-validate.directive';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TourServicesService } from '@modules/service/services/tour-services.service';
import { ITourDetail } from '@modules/service/models/tour-service.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonService, IPhoneNumberCode } from '@shared/services/common.service';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ValidationService } from '@shared/services/validation.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TourImportantNoteFormComponent } from '@admin/modules/tour-management/components/forms/tour-important-note-form/tour-important-note-form.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CAPTCHA_SITE_KEY } from '@assets/config/captchakey.config';
import { captchaValidator } from '@shared/services/contact-us.service';

@Component({
  selector: 'app-contact-form-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    CdkScrollable,
    FormValidateDirective,
    FormsModule,
    NzGridModule,
    NzImageModule,
    NzSegmentedModule,
    NzSelectModule,
    NzToolTipModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    CKEditorModule,
    TourImportantNoteFormComponent,
    RecaptchaModule
  ],
  templateUrl: './contact-form-modal.component.html',
  styleUrls: ['./contact-form-modal.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@UntilDestroy()
export class ContactFormModalComponent extends DefaultDialogComponent implements OnInit {
  contactForm: FormGroup;
  phoneNumberCountryCode: IPhoneNumberCode[] = [];
  isLoading: boolean = false;
  ckeditorConfig = {
    toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList']
  };
  siteKey: string = CAPTCHA_SITE_KEY;
  captcha: string | null;
  @ViewChild('tourImportantNoteFormComponent')
  tourImportantNoteFormComponent: TourImportantNoteFormComponent;
  constructor(
    private _FormBuilder: FormBuilder,
    private _TourServicesService: TourServicesService,
    private _CommonService: CommonService,
    public override dialogRef: MatDialogRef<ContactFormModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ITourDetail
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.initContactForm();
    this._CommonService
      .getPhoneCode()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.phoneNumberCountryCode = res;
      });
    this.contactForm.get('email')?.valueChanges.subscribe((e) => {
      this.contactForm.get('email')?.setValue(e.toLowerCase(), { emitEvent: false });
    });
  }

  initContactForm() {
    this.contactForm = this._FormBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      email: ['', [Validators.required, Validators.email]],
      phoneCountryCode: [''],
      phone: ['', [Validators.required, ValidationService.phoneValidator()]],
      requirement: [''],
      captcha: ['', captchaValidator]
    });
  }

  resolved(captchaResponse: string | null) {
    this.captcha = captchaResponse;
    this.contactForm.get('captcha')?.setValue(captchaResponse);
  }
  onContactFormSubmit() {
    this.contactForm.value.requirement = this.tourImportantNoteFormComponent.editedNote;
    this.isLoading = true;
    const body = { tourId: this.data.tour.id, ...this.contactForm.value };
    this._TourServicesService.paymentContact(body).subscribe(
      (res) => {
        this.isLoading = false;
        this.close();
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  get dayTripDuration() {
    if (this.data?.duration) {
      return this.data.duration as unknown as string;
    }
    return null;
  }
}
