import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-default-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultDialogComponent {
  constructor(public dialogRef: MatDialogRef<DefaultDialogComponent>) {}

  close(args?: any) {
    this.dialogRef.close(args);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
