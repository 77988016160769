<div class="footer-layout">
  <div class="container h-100">
    <div class="logo-container">
      <div class="logo">
        <img src="assets/logo/wowtrip-logo.png" alt="" />
      </div>
      <div class="text">
        Wowtrip is a Vietnam based travel company, run by a dedicated team of professional holiday
        planners, to provide you with high quality, high value holidays with an emphasis on
        personalized and attentive service.
      </div>
      <div class="social-media">
        <div class="social-media-icons">
          <a href="https://www.facebook.com/vnwowtrip" target="_blank" class="social-item">
            <img src="assets/icons/footer/facebook.svg" alt="" />
          </a>
          <a
            href="https://www.instagram.com/wowtrip.vn?igsh=dHp1dncyd25kOW94&utm_source=qr"
            class="social-item"
            target="_blank"
          >
            <img src="assets/icons/footer/instagram.svg" alt="" />
          </a>
          <a href="https://www.youtube.com/@wowtripvietnam7460" class="social-item" target="_blank">
            <img src="assets/icons/footer/youtube.svg" alt="" />
          </a>
          <a
            href="https://www.tripadvisor.com.vn/Attraction_Review-g298085-d24050263-Reviews-WOWTRIP_TRAVEL-Da_Nang.html"
            class="social-item"
            target="_blank"
          >
            <img src="assets/icons/footer/tripadvisor.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="xxx">
      <div class="xxx1 color-white">
        <div class="contact-us">
          <div class="contact-info">
            <div class="title">Hotline/whatsapp</div>
            <div
              (click)="call('+84905279348')"
              style="color: #72be2e"
              class="contact-method-title phone-number"
            >
              +84905279348
            </div>
          </div>
          <div class="contact-info">
            <div class="title">Head Office</div>
            <a href="https://maps.app.goo.gl/p2i6XPyiXSeUZhD3A" class="contact-method-title"
            >55 Ho Phi Tich st, Da Nang City, Vietnam</a
            >
          </div>
          <div class="contact-info">
            <div class="title">Branch Office</div>
            <a href="https://maps.app.goo.gl/qq6wGt27eT1StAEf9" class="contact-method-title"
            >18/28 Ong Ich Khiem st, Da Nang city, Vietnam</a
            >
          </div>
          <div class="contact-info">
            <div class="title">Branch Office</div>
            <a href="https://maps.app.goo.gl/MfnWt4FQNQgWrFFr6" class="contact-method-title"
            >Anland Premium Building,Hanoi, Vietnam</a
            >
          </div>
          <div class="contact-info">
            <div class="title">Business Registration</div>
            <div class="contact-method-title">0402143110</div>
          </div>
          <div class="contact-info">
            <div class="title">International Travel License</div>
            <div class="contact-method-title">48-276/TCDL-GPLHQT</div>
          </div>
        </div>
      </div>
      <div class="xxx2 color-white">
        <div class="information">
          <div class="group">
            <div class="title">ABOUT US</div>
            <div routerLink="about-us/why-wowtrip" class="topic">Why Wowtrip?</div>
            <div routerLink="about-us/our-people" class="topic">Our people</div>
          </div>
          <div class="group">
            <div class="title">COOPERATE & EVENT</div>
            <div routerLink="mice-and-events/danang-hoian-activities" class="topic">
              Activities in Da Nang/ Hoi An
            </div>
            <div routerLink="mice-and-events/meeting-incentive-service" class="topic">
              Meeting & incentive service
            </div>
          </div>

          <div class="group">
            <div class="title">TOUR SERVICE</div>
            <div routerLink="tour-services/package-tour" class="topic">Package tour</div>
            <div routerLink="tour-services/day-trip" class="topic">Day Trip</div>
            <div routerLink="tour-services/shore-excursion" class="topic topic--disabled">
              Shore excursion
            </div>
            <div routerLink="tour-services/education-trip" class="topic topic--disabled">
              Education trip
            </div>
          </div>
          <div class="group">
            <div class="title">MORE</div>
            <div routerLink="transport" class="topic">Transport</div>
            <div routerLink="about-us/testimonial" class="topic">Testimonial</div>
            <div routerLink="successful-events" class="topic">Our successful event</div>
            <div routerLink="about-us/our-clients" class="topic">Our clients</div>
            <div (click)="scrollToContactUS()" class="topic">Contact us</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-between h-100 flex-gap-y-4 pt-32px pb-3">
      <div class="payment-wrapper d-flex flex-gap-32px flex-wrap">
        <img
          style="max-width: 88px; max-height: 44px"
          *ngFor="let img of paymentLogo"
          [src]="img"
          alt=""
        />
      </div>
      <div class="section-bottom">
        <div class="copyright d-flex flex-gap-32px">
          <div class="copyright">© 2024 Wowtrip. All rights reserved.</div>
          <div class="additional-information">
            <span>Customer Support</span>
            <span>Privacy & Policy</span>
            <span (click)="scrollToContactUS()">Contact Us</span>
          </div>
        </div>
        <div class="power-by d-flex flex-gap-12px align-self-md-end align-self-sm-start">
          <div class="text">Website by</div>
          <img style="max-width: 155px; max-height: 28px" src="assets/logo/Logo-NeoP.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
