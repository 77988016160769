import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-layout',
  templateUrl: './banner-layout.component.html',
  styleUrls: ['./banner-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerLayoutComponent {
  @Input({ required: true }) imageUrl?: string;
  get backgroundImageUrl(): string {
    return `background-image: url(${this.imageUrl})`;
  }
}
