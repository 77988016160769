import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

export interface AuthResponse {
  user: User;
  token: string;
  rfToken: string;
  rfCsrfToken: string;
  csrfToken: string;
  rfTokenExp: number;
  tokenExp: number;
}

export interface Role {
  code: number;
  name: string;
}

export interface User {
  userId: string;
  email: string;
  role: Role;
  createdAt: Date;
  lastModified: Date;
  userName: string;
}

export enum ELocalStorageKeys {
  AUTH = 'auth'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  constructor(
    private _HttpClient: HttpClient,
    private _Router: Router
  ) {
    super(_HttpClient);
    this._HttpClient.get('', {});
  }

  login(data: { email: string; password: string }) {
    return this.post<AuthResponse>('user/login', 'v1', data);
  }

  loginGoogle(token: any) {
    return this.post<AuthResponse>('user/google/login', 'v1', token);
  }

  setAuthData(data: AuthResponse) {
    localStorage.setItem(ELocalStorageKeys.AUTH, JSON.stringify(data));
  }

  logout() {
    const header = new HttpHeaders({
      'X-TOKEN': `${this.accessToken}`,
      'X-REFRESH-TOKEN': `${this.refreshToken}`,
      'X-CSRF-TOKEN': `${this.csrfToken}`,
      'X-REFRESH-CSRF-TOKEN': `${this.rfCsrfToken}`
    });
    this.get('user/logout', 'v2', {}, header).subscribe((res) => {
      localStorage.removeItem(ELocalStorageKeys.AUTH);
      this._Router.navigate(['/auth']);
    });
  }

  refreshNewToken() {
    const header = new HttpHeaders({
      'X-REFRESH-TOKEN': `${this.refreshToken}`,
      'X-REFRESH-CSRF-TOKEN': `${this.rfCsrfToken}`
    });
    return this.get<{ token: string; csrfToken: string; tokenExp: number }>(
      'user/refresh',
      'v2',
      {},
      header
    ).pipe(
      catchError((error) => {
        this.logout();
        return throwError(error);
      })
    );
  }

  updateToken(token: string, csrfToken: string, tokenExp: number) {
    const authData = this.getUserInfo();
    if (authData) {
      authData.token = token;
      authData.csrfToken = csrfToken;
      authData.tokenExp = tokenExp;
      this.setAuthData(authData);
    }
  }

  get isLogged(): boolean {
    return Boolean(this.accessToken);
  }

  get accessToken() {
    return this.getUserInfo()?.token;
  }

  private get refreshToken() {
    return this.getUserInfo()?.rfToken;
  }

  private get rfCsrfToken() {
    return this.getUserInfo()?.rfCsrfToken;
  }

  get csrfToken() {
    return this.getUserInfo()?.csrfToken;
  }

  private get tokenExp() {
    return this.getUserInfo()?.tokenExp;
  }

  /**
   * Checks if the token is expired.
   *
   * This function compares the current time with the expiration time of the token.
   * If the current time is greater than the expiration time minus 60 seconds, it means the token is expired.
   * The function returns true if the token is expired, otherwise it returns false.
   * If there is no user info, the function considers the token as expired and returns true.
   *
   * @param {number} expiredTimestamp - The expiration time of the token in milliseconds since the Unix Epoch.
   * @returns {boolean} - Returns true if the token is expired, otherwise returns false.
   */
  checkIsTokenExpired(): boolean {
    const userInfo = this.getUserInfo();
    if (userInfo && this.tokenExp) {
      const currentTime = Math.floor(Date.now()) / 1000;
      const expiredTime = this.tokenExp! / 1000;
      return expiredTime - 60 < currentTime;
    }
    return true;
  }

  getUserInfo(): AuthResponse | null {
    const item = localStorage.getItem(ELocalStorageKeys.AUTH);
    return item ? (JSON.parse(item) as AuthResponse) : null;
  }
}
