import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { IErrorMessage, ValidationService } from '@shared/services/validation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Directive({
  selector: '[appFormValidate]',
  standalone: true
})
@UntilDestroy()
export class FormValidateDirective implements OnInit {
  private el: HTMLInputElement;
  @Input('validationMsgId') validationMsgId: string;
  errorSpanId: string = '';
  constructor(
    private control: NgControl,
    private elementRef: ElementRef,
    private _ValidateMessageService: ValidationService
  ) {
    this.el = this.elementRef.nativeElement;
  }
  ngOnInit() {
    this.errorSpanId = this.validationMsgId + '-error-msg';
    this.checkOnChange();
  }

  @HostListener('blur', ['$event'])
  handleBlurEvent(event: Event) {
    if (this.control.value == null || this.control.value == '') {
      if (this.control.errors) this.showError();
      else this.removeError();
    }
  }

  @HostListener('focus', ['$event'])
  handleFocusEvent(event: Event) {
    if (this.control.value == null || this.control.value == '') {
      if (this.control.errors) this.showError();
      else this.removeError();
    }
  }

  private checkOnChange() {
    this.control.statusChanges?.pipe(untilDestroyed(this)).subscribe((status) => {
      if (status == 'INVALID' && this.control.control?.touched) {
        this.showError();
      } else {
        this.removeError();
      }
    });
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors!;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsg = this._ValidateMessageService.getErrorMessages(firstKey as keyof IErrorMessage);
    const errSpan =
      '<p class="error-text" id="' +
      this.errorSpanId +
      '">' +
      '<i class="fa-sharp fa-solid fa-circle-exclamation"></i>' +
      errorMsg +
      '</p>';
    this.elementRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
    this.elementRef.nativeElement.classList.add('error');
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    this.elementRef.nativeElement.classList.remove('error');

    if (errorElement) errorElement.remove();
  }
}
